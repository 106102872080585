import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function listPlaces(params) {
  return request({
    url: ENDPOINT.LIST_PLACES,
    method: 'get',
    params
  })
}

export function createPlace(data) {
  return request({
    url: ENDPOINT.PLACE,
    method: 'post',
    data
  })
}

export function deletePlace(id) {
  return request({
    url: `${ENDPOINT.PLACE}?id=${id}`,
    method: 'delete'
  })
}

export function updatePlace(data) {
  return request({
    url: ENDPOINT.PLACE,
    method: 'patch',
    data
  })
}

export function getPlaceById(params) {
  return request({
    url: ENDPOINT.PLACE,
    method: 'get',
    params
  })
}

export function createPlaceRelated(data) {
  return request({
    url: ENDPOINT.PLACE_RELATED,
    method: 'post',
    data
  })
}

export function listPlaceRelated(params) {
  return request({
    url: ENDPOINT.PLACE_RELATED,
    method: 'get',
    params
  })
}
