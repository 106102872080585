import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function listTours(params) {
  return request({
    url: ENDPOINT.LIST_TOURS,
    method: 'get',
    params
  })
}
export function deleteTour(params) {
  return request({
    url: ENDPOINT.TOUR,
    method: 'delete',
    params
  })
}

export function createTour(data) {
  return request({
    url: ENDPOINT.TOUR,
    method: 'post',
    data
  })
}

export function updateTour(data) {
  return request({
    url: ENDPOINT.TOUR,
    method: 'patch',
    data
  })
}

export function getTourById(params) {
  return request({
    url: ENDPOINT.TOUR,
    method: 'get',
    params
  })
}

export function getTourDays(params) {
  return request({
    url: ENDPOINT.LIST_TOUR_DAYS,
    method: 'get',
    params
  })
}

export function createSchedule(data) {
  return request({
    url: ENDPOINT.TOUR_DAY,
    method: 'post',
    data
  })
}

export function updateSchedule(data) {
  return request({
    url: ENDPOINT.TOUR_DAY,
    method: 'patch',
    data
  })
}

export function deleteSchedule(params) {
  return request({
    url: ENDPOINT.TOUR_DAY,
    method: 'delete',
    params
  })
}

export function createTourDes(data) {
  return request({
    url: ENDPOINT.TOUR_DES,
    method: 'post',
    data
  })
}

export function updateTourDes(data) {
  return request({
    url: ENDPOINT.TOUR_DES,
    method: 'patch',
    data
  })
}

export function deleteTourDes(params) {
  return request({
    url: ENDPOINT.TOUR_DES,
    method: 'delete',
    params
  })
}

