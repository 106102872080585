import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function listPosts(params) {
  return request({
    url: ENDPOINT.LIST_POSTS,
    method: 'get',
    params
  })
}

export function getPostById(params) {
  return request({
    url: ENDPOINT.POST,
    method: 'get',
    params
  })
}

export function createPost(data) {
  return request({
    url: ENDPOINT.POST,
    method: 'post',
    data
  })
}

export function deletePost(id) {
  return request({
    url: `${ENDPOINT.POST}?id=${id}`,
    method: 'delete'
  })
}

export function updatePost(data) {
  return request({
    url: ENDPOINT.POST,
    method: 'patch',
    data
  })
}
